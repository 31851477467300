<template>
  <div class="sub-cont">
    <div class="mypage-wrap resume-wrap" style="min-height: auto">
      <div class="input-form resumeUpload">
        <div class="t-head resumeFile">
          입력이 어려우시면 파일을 첨부해주세요
        </div>
        <div class="t-doc">
          <input
            type="text"
            class="inp addr1 file-value"
            v-model="resumeFileName"
            readonly
          />
          <input
            type="file"
            ref="fileInput"
            class="hidden"
            @change="onFileChanged"
          />
          <button
            type="button"
            id="btn-file-upload"
            class="btn-func"
            @click="onUploadClick"
          >
            파일업로드
          </button>
        </div>
      </div>
    </div>

    <div class="mypage-wrap" style="padding-bottom: 0">
      <h2 class="h2-mypage">경력</h2>
      <button class="btn __add" @click="addForm">
        <i class="el-icon-plus"></i> 추가
      </button>
      <div class="empty_form" v-if="isCareerEmpty">경력사항을 추가하세요.</div>
      <div
        v-for="(career, ix) in careers"
        :key="`ca_${ix}`"
        class="input-form-wrap"
      >
        <div class="input-form">
          <div class="t-head">숙박업 유형</div>
          <div class="t-doc">
            <select
              v-model="career.businessTypeCode"
              name=""
              id=""
              class="select"
              :ref="`businessTypeCode_${ix}`"
            >
              <option value="">선택</option>
              <option
                v-for="(bizType, idx) in businessTypeCodeList"
                :key="`bt_${idx}`"
                :value="bizType.code"
                :selected="bizType.code == career.businessTypeCode"
              >
                {{ bizType.codeValue }}
              </option>
            </select>
          </div>
        </div>
        <div class="input-form">
          <div class="t-head">근무지</div>
          <div class="t-doc">
            <input
              type="text"
              v-model="career.company"
              class="text"
              :ref="`company_${ix}`"
            />
          </div>
        </div>
        <div class="input-form">
          <div class="t-head">근무직종</div>
          <div class="t-doc term">
            <!--div class="select-btn"-->
            <select
              class="select"
              name=""
              id=""
              v-model="career.jobPositionId"
              @change="changePositionId($event, ix)"
              :ref="`jobPositionId_${ix}`"
            >
              <option value="">선택</option>
              <option
                v-for="(jobPosition, idx) in jobPositionList"
                :key="`jp_${idx}`"
                :value="jobPosition.jobPositionId"
              >
                {{ `${jobPosition.positionName}` }}
              </option>
            </select>
            <select
              class="select"
              name=""
              :id="`jobPositionDtlId_${ix}`"
              v-model="career.jobPositionDtlId"
              :ref="`jobPositionDtlId_${ix}`"
              style="width: 110px"
            >
              <option value="">선택</option>
              <option
                v-for="(jobPositionDetail, idx) in jobPositionDtlCodeList"
                :key="`jp_${idx}`"
                :value="jobPositionDetail.jobPositionDtlId"
                :selected="
                  jobPositionDetail.jobPositionDtlId == career.jobPositionDtlId
                "
              >
                {{ `${jobPositionDetail.positionDtlName}` }}
              </option>
            </select>
            <!--/div-->
          </div>
        </div>
        <div class="input-form workStDate-form">
          <div class="t-head">근무시작</div>
          <input
            type="date"
            v-model="career.workStDate"
            class="inp datepicker"
            :id="`workStDate_${ix}`"
            autocomplete="off"
            inputmode="none"
            :ref="`workStDate_${ix}`"
          />
        </div>
        <div class="input-form">
          <div class="t-head">근무종료</div>
          <input
            type="date"
            class="inp datepicker"
            v-model="career.workEdDate"
            :id="`workEdDate_${ix}`"
            autocomplete="off"
            inputmode="none"
            :ref="`workEdDate_${ix}`"
          />
        </div>
        <div class="btn_box">
          <button class="btn __delete" @click="deleteCareer(ix)">삭제</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getBusinesTypeList,
  getJobPositionList,
  getJobPositionDetailList,
  getCareerList,
  saveCareerInfo,
  putResumeFileInfo,
  getBasicUserInfo,
  uploadUserImg,
  // putBasicUserInfo,
} from 'Api/modules'
import { mapGetters } from 'vuex'
import resumeMixin from 'Mixins/resume'
//import moment from 'moment'
// import datePickerMixin from 'Mixins/datePicker'

export default {
  mixins: [resumeMixin],
  data() {
    return {
      userId: '',
      businessTypeCodeList: [],
      jobPositionList: [],
      jobPositionDtlList: [],
      jobPositionDtlCodeList: [],
      // component: {
      //   test,
      // },
      resumeFileName: '',
      resumeNo: '',
      fileRegistration: false,
      careers: [
        {
          businessTypeCode: '',
          company: '',
          jobPositionId: '',
          jobPositionDtlId: '',
          workStDate: '',
          workEdDate: '',
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['account']),
    isCareerEmpty() {
      return this.careers.length == 0
    },
  },
  methods: {
    async changePositionId(event, ix) {
      const positionId = event.target.value
      this.getPositionDtlId(positionId, '', ix)
    },
    async getPositionDtlId(positionId, jobPositionDtlId, ix) {
      // 세부직종을 조회한다.
      try {
        const res = await getJobPositionDetailList({
          positionId,
        })
        //console.info(ix, res)
        // if (res) this.jobPositionDtlCodeList[ix] = res
        var option = '<option value="">선택</option>'
        if (res && positionId) {
          for (var i = 0; i < res.length; i++) {
            //console.info(res[i].jobPositionDtlId, res[i].positionDtlName)
            if (jobPositionDtlId == res[i].jobPositionDtlId) {
              option =
                option +
                '<option value="' +
                res[i].jobPositionDtlId +
                '" selected>' +
                res[i].positionDtlName +
                '</option>'
            } else {
              option =
                option +
                '<option value="' +
                res[i].jobPositionDtlId +
                '">' +
                res[i].positionDtlName +
                '</option>'
            }
          }
        }
        window.$('#jobPositionDtlId_' + ix).empty()

        window.$('#jobPositionDtlId_' + ix).append(option)

        return true
      } catch (e) {
        return false
      }
    },
    async save() {
      //console.info('careers save', this.careers)
      //입력하지 않은 값 검사
      if (!this.checkValues(this.careers)) return false
      try {
        await saveCareerInfo({
          careers: this.careers,
        })
        if (this.fileRegistration == true) {
          await putResumeFileInfo({
            userId: this.account.userId,
            resumeFileName: this.resumeFileName,
            resumeNo: this.resumeNo,
          })
        }
        this.$toasted.show('경력사항이 등록되었습니다.')
        console.log(this.saveCareerInfo())
        return true
      } catch (e) {
        return false
      }
    },
    deleteCareer(idx) {
      this.deleteItemOrClearForm(this.careers, idx)
    },
    addForm() {
      this.careers.push({
        businessTypeCode: '',
        company: '',
        jobPositionId: '',
        jobPositionDtlId: '',
        workStDate: '',
        workEdDate: '',
      })
    },
    async fetchPersonalInfo() {
      const res = await getBasicUserInfo()
      this.userId = this.account.userId
      this.resumeFileName = res.resumeFileName
      this.resumeNo = res.resumeNo
    },
    async onFileChanged(event) {
      // 1M
      var maxFileSize = 1 * 1024 * 1000
      // console.log(file.size)
      const file = event.target.files[0]
      if (file) {
        const isAcceptable = [
          'image/jpeg',
          'image/png',
          'image/bmp',
          'image/gif',
          'application/pdf', //pdf
          'application/vnd.ms-excel', //xls
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', //xlsx
          'application/haansofthwp', //hwp
        ].includes(file.type)
        if (file.size > maxFileSize) {
          this.$toasted.error('파일 크기는 1M이하만 가능합니다.')
          return false
        } else if (!isAcceptable) {
          this.$toasted.error('허용하지 않는 파일 형식입니다.')
          return false
        } else {
          this.fileRegistration = true
          this.$toasted.show('파일이 업로드되었습니다.')
        }
        if (isAcceptable) {
          this.resumeFileName = file.name
          try {
            const res = await uploadUserImg({ uploadFile: file })
            this.resumeNo = res
            var reader = new FileReader()
            reader.onload = e => {
              this.inputPhoto = e.target.result
            }
            reader.readAsDataURL(file)
          } catch (e) {
            console.error(e)
            return
          }
        }
      }
    },
    onUploadClick() {
      this.$refs['fileInput'].click()
    },
  },
  async created() {
    //숙박업 유형 목록 조회
    this.businessTypeCodeList = await getBusinesTypeList()

    // 직무코드 조회
    //직종 목록 조회
    const res1 = await getJobPositionList()
    this.jobPositionList = res1
    //const res2 = await getJobPositionDetailCodeList()
    //this.jobPositionDtlCodeList[0] = res2
    await this.fetchPersonalInfo()
    // 경력목록 조회
    try {
      const res = await getCareerList({ userId: this.account.userId })
      if (res && res.length > 0) {
        this.careers = res
        for (var i = 0; i < res.length; i++) {
          this.getPositionDtlId(
            this.careers[i].jobPositionId,
            this.careers[i].jobPositionDtlId,
            i,
          )
        }
      }

      return true
    } catch (e) {
      console.error(e)
      return false
    }
  },
}
</script>

<style>
.empty_form {
  padding-top: 90px;
  height: 160px;
  border: 1px;
  border-style: none none solid none;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
}
</style>
